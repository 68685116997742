import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import HeroGeneric from "../components/HeroGeneric";
import Layout from "../components/layout";
import HelpArticle from "../components/HelpArticle";

export default function promotionsTermsSingle({ data }) {
	const { heroTitle, contentText, metaTitle, metaDescription, metaShareImage } =
		data.craft.entry ?? [];

	return (
		<Layout className="layout--helpIndex">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGeneric title={heroTitle} colorTheme="dark" nextTheme="light" />

			<HelpArticle content={contentText} />
		</Layout>
	);
}

export const pageQuery = graphql`
	query promotionsTermsSingle($id: CraftCMS_QueryArgument!) {
		craft {
			entry(id: [$id]) {
				slug
				uri
				title
				... on CraftCMS_promotionsTermsConditions_promotionsTermsAndConditions_Entry {
					heroTitle
					contentText
					metaTitle
					metaDescription
					metaShareImage {
						filename
					}
				}
			}
		}
	}
`;
